import React, { Suspense } from 'react'
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'

const Home = React.lazy(() => import('../home'))

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/'>
            <Suspense fallback={<></>}><Home /></Suspense>
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App
